@font-face {
  font-family: 'Akkurat';
  src: url('/public/Akkurat-Mono.OTF');
}

body {
  margin: 0;
  font-family: 'Akkurat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Akkurat', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-container {
  height: 100%;
  width: 100vw;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .scroll-container {
    height: 100%;
  }
}
